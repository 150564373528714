
.logo.no-style {
  display: flex;
  align-items: center;

  margin: 0;

  font-family: 'Lobster', cursive;
  font-weight: 400;
  font-size: 36px;

  color: #FFFFFF;
}

.logo-img {
  height: 60px;
  width: 60px;

  margin-right: 30px;
}

.logo.no-style.big {
  display: flex;
  flex-direction: column;

  font-size: 48px;

  .logo-img {
    width: 50vw;
    max-width: 250px;
    height: auto;
  }
}

@media (max-width: 955px) {
  .logo.no-style {
    width: 160px;
    font-size: 24px;
  }

  .logo-img {
    height: 40px;
    width: 40px;
    margin-right: 15px;
  }
}
