
.contact-info {
  width: 100%;
}

.contact-row {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.contact-item {
  margin-top: 18px;

  a.icon {
    font-size: 24px;
  }
}

.contact-info.blue {
  .contact-item a.icon {
    color: #4267B2;
  }
}

@media (max-width: 550px) {
  .contact-row {
    flex-direction: column;
    align-items: center;
  }
}
