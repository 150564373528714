
.nav {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 80px;

  padding: 0 100px;

  background: #60C26D;

  color: #FFFFFF;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

  z-index: 10;
}

.nav-options {
  align-self: stretch;
  
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  li {
    width: 100px;

    font-size: 14px;
    font-weight: 700;

    text-align: center;

    border-right: 1px solid #58B464;  

    &:first-of-type {
      border-left: 1px solid #58B464;
    }

    a {
      display: block;

      height: 80px;
      width: 100px;

      line-height: 80px;

      &.selected {
        background: #5CBB69;
      }
    }
  }
}

.nav-mobile.no-style {
  display: none;
  justify-content: center;
  align-items: center;

  border: 1px solid #58B464;
  background: #5CBB69;
  padding: 10px;
  border-radius: 4px;
}


@media (max-width: 1100px) {
  .nav-title {
    width: 245px;
  }
}

@media (max-width: 955px) {
  .nav {
    padding: 0 25px;
  }
  
  .nav-mobile.no-style {
    display: flex;
  }

  .nav-options {
    display: none;

    &.open {
      display: block;
      position: absolute;
      right: 0;
      left: 50px;
      top: calc(100% - 1px);

      background: #60C26D;
    }

    li {
      width: 100%;
  
      border-bottom: 1px solid #58B464;  
  
      &:last-of-type {
        border-bottom: none;
      }
  
      a {
        width: unset;
        height: 80px;
        line-height: 80px;

        &.selected {
          background: #5CBB69;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .nav {
    padding: 0 10px;
  }
}
