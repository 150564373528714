
.app {
  display: flex;
  flex-direction: column;
}

.app-content {
  height: calc(100vh - 80px);
  width: 100vw;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

