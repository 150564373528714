
.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 100px 0;

  font-size: 144px;

  color: #60C26D;

  .icon {
    background: #FFF;

    border-radius: 100%;
    padding: 20px;

    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform:rotate(360deg);
  }
}

@media (max-width: 955px) {
  .loading {
    margin: 25px 0;

    font-size: 72px;
  }
}

@media (max-width: 500px) {
  .loading {
    margin: 10px 0;

    font-size: 72px;
  }
}
