

.footer {
  background: #60C26D;

  font-size: 18px;
  font-weight: 700;

  color: #FFFFFF;
}

.footer-container {
  display: flex;
  
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
}

.footer-side {
  flex: 0 0 50%;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.links {
    padding-top: 80px;
  }
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    margin-bottom: 14px;
  }
}

.footer-attribution {
  background: #56AD62;

  width: 100%;

  padding: 5px;

  margin-top: 50px;

  font-size: 14px;
  font-weight: 700;

  text-align: center;
}

@media (max-width: 955px) {
  .footer-container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (max-width: 760px) {
  .footer-container {
    flex-direction: column;
  }
  
  .footer-links {
    align-items: center;
  }
}

@media (max-width: 500px) {
  .footer-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer-side.links {
    padding-top: 20px;
  }
}
