
@import-normalize;

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #E5E5E5;

  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, textarea {
  margin: 10px;
  padding: 10px;

  background-image: none;
  background-color: transparent;

  border: 1px solid #E5E5E5;
  border-radius: 10px;

  box-shadow: none;

  font: inherit;
}

textarea {
  height: 100px;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

a.no-style {
  &, &:link, &:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }
}

ul.no-style {
  margin: 0;
  
  margin-block-start: 0;
  margin-block-end: 0;
  
  margin-inline-start: 0;
  margin-inline-end: 0;
  
  padding-inline-start: 0;

  list-style-type: none;
}

button.no-style {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.bolden {
  font-weight: 700;
}
